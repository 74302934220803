import axios from "axios";
//APIキー
const apiKey ="17f47bff901091c6e228276671a7fbf252de06b805cd94ef803633cb0c4f4fcc7f0b40e6e6711bddfd06909e9b8752d32e8eb500a9f985f2680768eb4bfc7755";
const pleasanter = {
    getPleasanter: async (siteId) => {
        const url = "/api/items";
        const json = {
            ApiVersion: 1.1,
            ApiKey: apiKey,
        };
        const response = await axios.post(`${url}/${siteId}/get`, json);
        //改行区切りの文字列を配列に変換
        return response.data.Response.Data[0].Body.split("\n");
    },
    getHistoryPleasanter: async (carNumber) => {
        const siteId = "1";
        const url = "/api/items";
        const json = {
            ApiVersion: 1.1,
            ApiKey: apiKey,
            View: {
                ColumnFilterHash: {
                    ClassA: `["${carNumber}"]`,
                },
                ColumnSorterHash: {
                    DateA: "desc",
                },
            },
        };        
        //車両番号に一致するデータを取得 
        const response = await axios.post(`${url}/${siteId}/get`, json);
        //データを返却
        return response.data.Response.Data[0];
    },
    getHistoryPleasanterTop10: async (carNumber) => {
        const siteId = "1";
        const url = "/api/items";
        const json = {
            ApiVersion: 1.1,
            ApiKey: apiKey,
            View: {
                ColumnFilterHash: {
                    ClassA: `["${carNumber}"]`,
                    ClassF: `["2"]`
                },
                ColumnSorterHash: {
                    DateA: "desc",
                },
            },
        };        
        //車両番号に一致するデータを取得 
        const response = await axios.post(`${url}/${siteId}/get`, json);
        //上位10件のデータを返却
        let data =  response.data.Response.Data.slice(0, 10);
        //逆順にする
        data.reverse();
        return data;
    },
    setPleasanter: async (formData) => {
        const siteId = '1';
        //現在時刻（YYYY-MM-DDTHH:MM:SS）を取得        
        const date = new Date();
        const year = date.getFullYear();
        
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);
        const createdTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

        const url = "/api/items";

        //添付ファイルチェック
        if(formData['AttachmentsA'] === ""){
            formData['AttachmentsA'] = [];
        }else{
            formData['AttachmentsA'] = [{
                Name: "charDamage.jpg",
                ContentType: "image/jpeg",
                Base64: formData['AttachmentsA']
            }]
        }
        if(formData['AttachmentsB'] === ""){
            formData['AttachmentsB'] = [];
        }else{
            formData['AttachmentsB'] = [{
                Name: "alcoholCheck.jpg",
                ContentType: "image/jpeg",
                Base64: formData['AttachmentsB']
            }]
        }
        const json = {
            ApiVersion: 1.1,
            ApiKey: apiKey,
            Body: "",
            CreatedTime: createdTime,
            ItemTitle: "運転日報",
            ClassHash: {
                ClassA: formData['ClassA'],
                ClassB: formData['ClassB'],
                ClassD: formData['ClassD'],
                ClassF: formData['ClassF'],
                ClassG: formData['ClassG']
            },
            NumHash: {
                NumA: formData['NumA'],
                NumB: formData['NumB'],
                NumH: formData['NumH'],
                NumJ: formData['NumJ'],
            },
            DateHash: {
                DateA: formData['DateA']
            },
            DescriptionHash: {
                DescriptionB: formData['DescriptionB'],
                DescriptionC: formData['DescriptionC'],
                DescriptionD: formData['DescriptionD']
            },
            CheckHash: {
                CheckA: formData['CheckA'],
            },
            AttachmentsHash: {
                AttachmentsA: formData['AttachmentsA'],
                AttachmentsB: formData['AttachmentsB']
            }
        };
        console.log(json);
        

        const response = await axios.post(`${url}/${siteId}/create`, json);
        return response;
    }
};

export default pleasanter;
