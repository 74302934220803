import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useEffect, useState } from "react";
import pleasanter from "./pleasanter";
import swal from "sweetalert2";
import dayjs from "dayjs";

const Loading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(94, 94, 94, 0.8);
  z-index: 1000;
  //くるくるアニメーション
  .spinner {
    position: absolute;
    top: calc(50% - 2.5em);
    left: calc(50% - 2.5em);
    width: 5em;
    height: 5em;
    border-radius: 50%;
    border: 0.25em solid #ccc;
    border-top-color: #75df55;
    animation: spinner 1s linear infinite;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Container = styled.div`
  padding: 10px;
  background-color: #f0ffea;
  div {
    margin-top: 10px;
  }
  div.last {
    margin-bottom: 100px;
  }
`;

const TabArea = styled.div`
  display: flex;
  .tab {
    width: 50%;
    text-align: center;
    padding: 10px;
    border: 1px solid #dee2e6;
    cursor: pointer;
    background-color: #f5f5f5;
  }
  .tab-left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .tab-right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .tab-left.active {
    background-color: #007bff;
    color: white;
  }
  .tab-right.active {
    background-color: #ff8d00;
    color: white;
  }
`;

const FixedBottomArea = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background-color: #f5f5f5; /* 背景色 */
  border-top: 1px solid #adadad; /* 上部の境界線 */
  z-index: 1000;
`;
const ComplateArea = styled.div`
    width: 80%;
    background: white;
    margin: 0 auto;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
    img{
      width: 80%;
    }
`;
export default function Home() {
  const [isLoading, setIsLoading] = useState(false); // ローディング状態の管理
  const [carNumbers, setCarNumbers] = useState([]);
  const [selectedCarNumber, setSelectedCarNumber] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(0);
  const [datetime, setDateTime] = useState("");
  const [destination, setDestination] = useState("");
  const [selectedKubun, setSelectedKubun] = useState(1);
  const [selectedCheckA, setSelectedCheckA] = useState("");
  const [selectedClassD, setSelectedClassD] = useState(1);
  const [requiredAlcohol, setRequiredAlcohol] = useState(true);
  const [inputCompleted, setInputCompleted] = useState(false);

  useEffect(() => {
    //マスターのサイトID
    const siteIds = ["3","5","558"];
    //ローディング開始
    setIsLoading(true);
    console.log("ローディング開始");
    let masterCars = [];
    let masterDepartments = [];
    let masterDrivers = [];
    //マスタのデータを取得
    const fetchData = async () => {
      for (const siteId of siteIds) {
        const masterDatas = await pleasanter.getPleasanter(siteId);
        console.log("データ取得");
        switch (siteId) {
          case "3":
            //車両情報をセット
            masterCars = masterDatas;
            setCarNumbers(masterCars);
            break;
          case "5":
            //部署情報をセット
            masterDepartments = masterDatas;
            setDepartments(masterDepartments);
            break;
          case "558":
            //運転者情報をセット
            masterDrivers = masterDatas;
            setDrivers(masterDrivers);
            break;
        }
      }
      //ローディング終了
      setIsLoading(false);
      console.log("ローディング終了");

      //ローカルストレージから部署と運転者の選択値を取得
      const selectedDepartment = localStorage.getItem("selectedDepartment");
      const selectedDriver = localStorage.getItem("selectedDriver");
      if (selectedDepartment) {
        setSelectedDepartment(Number(selectedDepartment));
      }
      if (selectedDriver) {
        setSelectedDriver(Number(selectedDriver));
      }

      //URLのGETパラメータを取得
      let url = new URL(window.location.href);
      // URLSearchParamsオブジェクトを取得
      let params = url.searchParams;
      // getメソッド
      if (params.get("carNumber") != null) {
        // carNumberを数値に変換
        let carNumber = Number(params.get("carNumber"));
        // carNumberをセット
        setCarNumber(carNumber, masterCars , masterDepartments, masterDrivers);
      }


      //時刻の再設定
      handleUpdateDate();
      
    };

    //データ取得処理実行
    fetchData();
  }, []);

  /**
   * 車体番号を選択した際の処理
   */
  const setCarNumber = async (carNumber, cars = carNumbers , departmentArray = departments , driverArray = drivers) => {
    if (carNumber === "") {
      return;
    }
    //ローディング開始
    setIsLoading(true);
    setSelectedCarNumber(carNumber);
    //前回登録データを取得
    const historyData = await pleasanter.getHistoryPleasanter(carNumber);

    //前回登録データがある場合
    if (historyData !== undefined) {
      //運転前運転後区分を取得
      const kbn = historyData["ClassHash"]["ClassF"];
      //前回のデータが「出庫」だった場合、訪問先を取得し、設定
      if (kbn == 1) {
        //訪問先のテキストを取得し、設定
        const destination = localStorage.getItem("destination");
        if (destination) {
          setDestination(destination);
        }
        handleSelectedKubun("enter");
      } else if(kbn == 2){
        //前回のデータが「入庫」だった場合、運転者を取得し、設定
        handleSelectedKubun("leave");
        //historyData["DescriptionHash"]["DescriptionC"]の文頭に[!]がある場合、メッセージを表示
        if (historyData["DescriptionHash"]["DescriptionC"].startsWith("[!]")) {
          let title = "-前回運転者からの申し送り事項-";
          let text = "";
          text += `<div class="prevDataArea">`;
          //部署の名前を取得
          const departmentName = departmentArray.find((item) => {
            return item.split(",")[0] == historyData["ClassHash"]["ClassB"];
          });
          text += `<label class="prevData">部署</label>：${departmentName != undefined? departmentName.split(",")[1]:"部署不明"}<br>`;

          //運転者の名前を取得
          const driverName = driverArray.find((item) => {
            return item.split(",")[0] == historyData["NumHash"]["NumJ"];
          });
          text += `<label class="prevData">運転者</label>：${driverName != undefined? driverName.split(",")[1] +"(" +historyData["NumHash"]["NumJ"] +")": historyData["NumHash"]["NumJ"]}<br>`;
          text += `<label class="prevData">申し送り事項</label>：${historyData["DescriptionHash"]["DescriptionC"].replace("[!]","")}<br>`;
          text += `</div>`;
          swal.fire({
            title: title,
            html: text,
            icon: "",
            confirmButtonText: "閉じる",
          });
        }
        
      }
    } else {
      handleSelectedKubun("leave");
    }

    //選択された車体番号の値に紐づく名称を取得
    const carNumberName = cars.find((item) => {
      return item.split(",")[0] == carNumber;
    });

    //取得した名称に「本社」が含まれる場合、アルコールチェックを非表示
    if (carNumberName.includes("本社")) {
      setRequiredAlcohol(true);
      setSelectedClassD(1);
    } else {
      setRequiredAlcohol(false);
      setSelectedClassD(3);
    }

    //ローディング終了
    setIsLoading(false);
  };
  /**
   * 入出庫日時を設定(24時間表記)
   */
  const handleUpdateDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hour = now.getHours();
    const minute = now.getMinutes();
    const dateTime = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}T${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;

    setDateTime(dateTime);
  };

  /**
   * 入出庫区分変更処理
   * @param {*} kubun
   * @returns
   */
  const handleSelectedKubun = (kubun) => {
    if (kubun === "leave") {
      document.getElementsByClassName("tab-left")[0].classList.add("active");
      document.getElementsByClassName("tab-right")[0].classList.remove("active");

      //給油量(Class:form-groupNumA)の項目を非表示
      document.getElementsByClassName("form-groupNumA")[0].style.display ="none";
      //申し送りチェック（id:form-next-driver-msg)を非表示
      document.getElementById("form-next-driver-msg").style.display ="none";
      //入出庫区分(CassF)の値を変更
      setSelectedKubun(1);
    } else {
      document.getElementsByClassName("tab-left")[0].classList.remove("active");
      document.getElementsByClassName("tab-right")[0].classList.add("active");

      //給油量の項目を表示
      document.getElementsByClassName("form-groupNumA")[0].style.display ="block";
      //申し送りチェック（id:form-next-driver-msg)を表示
      document.getElementById("form-next-driver-msg").style.display ="block";
      //入出庫区分(CassF)の値を変更
      setSelectedKubun(2);
    }
  };

  /**
   * 申し送りチェック変更処理
   */
  const handleCheckChange = () => {
    const checkB = document.getElementById("CheckB");
    const descriptionC = document.getElementById("DescriptionC");
    if (checkB.checked) {
      //DescriptionCに入力されている場合
      if (descriptionC.value !== "") {
        //DescriptionCの文頭に[!]を追加      
        descriptionC.value = "[!]" + descriptionC.value;
      }else{
        //DescriptionCに入力されていない場合,checkBのチェックを外す
        checkB.checked = false;
        swal.fire({
          title: "その他・同乗者の入力がありません",
          icon: "error",
          confirmButtonText: "閉じる",
        });
      }
    }else{
      //DescriptionCの文頭の[!]を削除
      descriptionC.value = descriptionC.value.replace("[!]","");
    }
  };


  /**
   * 車両損傷ラジオボタン変更処理
   * @param {*} e
   */
  const handleRadioChange = (e) => {
    //nameがCheckAのラジオボタンの値を取得
    if (e.target.value === "0") {
      setSelectedCheckA(false);
    } else {
      setSelectedCheckA(true);
      swal.fire({
        title: "損傷がある場合は人事総務課に連絡してください",
        html: "<a href='tel:0776-67-8000'><button class='btn btn-danger'>人事総務課にTEL</button></a>",
        icon: "warning",
        confirmButtonText: "閉じる",
      });
    }
  };
  
  /**
   * アルコール測定方法変更処理
   * @param {*} e
   * @returns
   */
  const handleClassDChange = (e) => {
    setSelectedClassD(e.target.value);
  };

  /**
   * 画像選択ボタンを押下した際の処理
   * @param {*} id
   */
  const selectImage = (id) => {
    document.getElementById(id).click();
  };

  /**
   * ファイルアップロード時の処理
   * @param {*} e
   */
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    //idからファイル名を取得(idの「-file」を削除)
    let id = e.target.id;
    id = id.replace("-file", "");
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const jpegBase64 = canvas
            .toDataURL("image/jpeg", 0.3)
            .replace(/^data:\w+\/\w+;base64,/, "");
          //AttachmentsAにBase64文字列をセット
          document.getElementById(id).value = jpegBase64;
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  /**
   * 直近の運転履歴を参照ボタン押下時の処理
   * @returns 
   */
  const handleCheckHistoryDataTop10 = async function () {
    //前回登録データを取得
    const historyDatas = await pleasanter.getHistoryPleasanterTop10(
      selectedCarNumber
    );
    if (historyDatas === undefined) {
      swal.fire({
        title: "前回の登録データがありません",
        icon: "error",
        confirmButtonText: "閉じる",
      });
      return;
    }

    let title = "-運転履歴-";
    let text = "<p>直近の運転履歴を表示します。</p>";
    text += `<div class="overflow-scroll">`;
    historyDatas.forEach((historyData) => {
      text += `<div class="prevOilingDataArea">`;
      text += `<label class="prevData">入出庫日時</label>：${dayjs(
        historyData["DateHash"]["DateA"]
      ).format("YYYY年M月D日 HH:mm")}<br>`;

      //部署の名前を取得
      const departmentName = departments.find((item) => {
        return item.split(",")[0] == historyData["ClassHash"]["ClassB"];
      });
      text += `<label class="prevData">部署</label>：${
        departmentName.split(",")[1]
      }<br>`;

      //運転者の名前を取得
      const driverName = drivers.find((item) => {
        return item.split(",")[0] == historyData["NumHash"]["NumJ"];
      });
      text += `<label class="prevData">運転者</label>：${
        driverName != undefined
          ? driverName.split(",")[1] +
            "(" +
            historyData["NumHash"]["NumJ"] +
            ")"
          : historyData["NumHash"]["NumJ"]
      }<br>`;

      text += `<label class="prevData">訪問先</label>：${historyData["DescriptionHash"]["DescriptionB"]}<br>`;
      text += `<label class="prevData">ODOメータ</label>：${Number(
        historyData["NumHash"]["NumH"]
      ).toLocaleString()}km<br>`;
      text += `<label class="prevData">給油量</label>：${
        historyData["NumHash"]["NumA"]
      }L ${historyData["NumHash"]["NumA"] > 0 ? "⛽" : ""}<br>`;
      if (historyData["DescriptionHash"]["DescriptionC"] != "") {
        text += `<label class="prevData">その他</label>：${historyData["DescriptionHash"]["DescriptionC"]}<br>`;
      }
      text += `</div>`;
      text += `<div class="historyBranch"></div>`;
    });
    text += `<div class="w-100 text-start">現在</div>`;
    text += `</div>`;
    swal.fire({
      title: title,
      html: text,
      icon: "",
      confirmButtonText: "閉じる",
    });
  };

  /**
   * 登録ボタン押下時の処理
   * @param {*} e
   * @returns
   */
  const handleSubmit = async () => {
    //必須項目の入力チェック
    const requiredElements = document.getElementsByClassName("required");
    for (let i = 0; i < requiredElements.length; i++) {
      const element = requiredElements[i];
      element.style.borderColor = "#dee2e6";
      //hiddenの場合、親要素の枠を元に戻す
      if (element.type === "hidden") {
        element.parentElement.style.border = "none";
      }
      if (element.value === "") {
        //該当項目の枠を赤くする
        element.style.borderColor = "red";
        //hiddenの場合、親要素の枠を赤くする
        if (element.type === "hidden") {
          element.parentElement.style.border = "1px solid red";
        }
        swal.fire({
          title: "必須項目が入力されていません",
          icon: "error",
          confirmButtonText: "閉じる",
        });
        return;
      }
    }
    //selectedClassDが2の場合、確認者と確認方法の入力チェック
    if (selectedClassD === "2") {
      let textD = document.getElementById("DescriptionD");
      let classG = document.getElementById("ClassG");
      textD.style.borderColor = "#dee2e6";
      classG.style.borderColor = "#dee2e6";
      if (textD.value === "" || classG.value === "") {
        if (textD.value === "") {
          textD.style.borderColor = "red";
        }
        if (classG.value === "") {
          classG.style.borderColor = "red";
        }
        swal.fire({
          title: "必須項目が入力されていません",
          icon: "error",
          confirmButtonText: "閉じる",
        });
        return;
      }
    }

    //アルコール検出値が0でない場合、メッセージ
    if (document.getElementById("NumB").value != 0) {
      swal.fire({
        title:
          "アルコール検出値が0mgではありません！検出値が0mgでない場合、所属長に連絡の上、運転はせず所属長の指示に従ってください。",
        icon: "error",
        confirmButtonText: "閉じる",
      });
      return;
    }

    //前回登録データを取得
    const historyData = await pleasanter.getHistoryPleasanter(
      selectedCarNumber
    );
    if (historyData != undefined) {
      //historyDataの入出庫区分（ClassF）を取得
      const historyKubun = historyData["ClassHash"]["ClassF"];
      const selectedKubun = document.getElementById("ClassF").value;
      if (historyKubun === selectedKubun) {
        let reuslt = await swal
          .fire({
            title: "同じ入出庫区分が連続して登録されていますがよろしいですか？",
            icon: "warning",
            confirmButtonText: "はい",
            cancelButtonText: "いいえ",
            showCancelButton: true,
          })
          .then((result) => {
            return result.isConfirmed;
          });
        if (!reuslt) {
          return;
        }
      }
      //前回のメータ値を取得
      const historyMeter = historyData["NumHash"]["NumH"];
      const selectedMeter = document.getElementById("NumH").value;
      if (historyMeter > selectedMeter) {
        let reuslt = await swal
          .fire({
            title:
              "前回のメータ値より小さい値が入力されていますがよろしいですか？",
            icon: "warning",
            confirmButtonText: "はい",
            cancelButtonText: "いいえ",
            showCancelButton: true,
          })
          .then((result) => {
            return result.isConfirmed;
          });
        if (!reuslt) {
          return;
        }
      }
    }

    //#reportFormの入力内容をidをキーにして取得
    const formData = {};
    const form = document.getElementById("reportForm");
    const elements = form.elements;
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const id = element.id;
      const value = element.value;
      if (id) {
        //チェックボックスの場合、チェックされているかどうかでtrue/falseをセット
        if (element.type === "checkbox") {
          formData[id] = element.checked;
        } else {
          formData[id] = value;
        }
      }
    }

    //確認画面を表示
    let text = "";
    text += `<div class="prevDataArea">`;
    text += `<label class="prevData">入出庫区分</label>：${
      formData["ClassF"] == 1 ? "運転前" : "運転後"
    }<br>`;
    text += `<label class="prevData">車体番号</label>：${formData["ClassA"]}<br>`;
    text += `<label class="prevData">部署</label>：${formData["ClassB"]}<br>`;
    text += `<label class="prevData">運転者</label>：${formData["NumJ"]}<br>`;
    text += `<label class="prevData">入出庫日時</label>：${dayjs(
      formData["DateA"]
    ).format("YYYY年M月D日 HH:mm")}<br>`;
    text += `<label class="prevData">訪問先</label>：${formData["DescriptionB"]}<br>`;
    text += `<label class="prevData">ODOメータ</label>：${Number(
      formData["NumH"]
    ).toLocaleString()}km<br>`;
    text += `<label class="prevData">給油量</label>：${formData["NumA"]}L<br>`;
    text += `<label class="prevData">その他</label>：${formData["DescriptionC"]}<br>`;
    text += `</div>`;

    let result = await swal
      .fire({
        title: "以下の内容で登録しますか？",
        html: text,
        icon: "warning",
        confirmButtonText: "はい",
        cancelButtonText: "いいえ",
        showCancelButton: true,
      })
      .then((result) => {
        return result.isConfirmed;
      });
    if (!result) {
      return;
    }
    //ローディング開始
    setIsLoading(true);
    //プリザンターへ登録
    const response = await pleasanter.setPleasanter(formData);
    //ローディング終了
    setIsLoading(false);
    if (response.status !== 200) {
      swal.fire({
        title: "登録に失敗しました。再度登録をお願いします",
        icon: "error",
        confirmButtonText: "閉じる",
      });
      return;
    }

    //部署と運転者の選択値をローカルストレージに保存
    localStorage.setItem("selectedDepartment", selectedDepartment);
    localStorage.setItem("selectedDriver", selectedDriver);
    //運転前の場合、訪問先のテキストをローカルストレージに保存
    if (formData["ClassF"] == 1) {
      localStorage.setItem("destination", formData["DescriptionB"]);
    } else {
      localStorage.setItem("destination", "");
    }

    //name=CheckA(車体損傷)のラジオボタンをchecked:falseに
    const radioButtons = document.getElementsByName("CheckA");
    for (let i = 0; i < radioButtons.length; i++) {
      radioButtons[i].checked = false;
    }
    document.getElementById("CheckA").value = "";

    swal.fire({
      title: "登録が完了しました!",
      icon: "success",
      confirmButtonText: "閉じる",
    });
    setInputCompleted(true);
  };

  return (
    <>
      <Container>
        {isLoading && (
          <Loading>
            <div className="spinner"></div>
          </Loading>
        )}
        <h2 className="mt-3">運転日報フォーム</h2>
        {inputCompleted && (
          <ComplateArea>
            <h3>入力が完了しました。</h3>
            {selectedKubun === 2 && (
              <h3>運転お疲れさまでした！</h3>
            )}
            <img src="completed.png" alt="complete" />
            <button className="btn btn-primary" onClick={()=>{window.location.reload(true)}}>フォームに戻る</button>
          </ComplateArea>
        )}
        {!inputCompleted && (
          <form id="reportForm" className="mb-10">
            <div className="form-group no-flex">
              <TabArea>
                <div
                  id="tab-leave"
                  className="tab tab-left active"
                  onClick={() => {
                    handleSelectedKubun("leave");
                  }}
                >
                  運転前
                </div>
                <div
                  id="tab-enter"
                  className="tab tab-right"
                  onClick={() => {
                    handleSelectedKubun("enter");
                  }}
                >
                  運転後
                </div>
              </TabArea>
              <input type="hidden" id="ClassF" value={selectedKubun} />
            </div>
            <div className="form-group mb-1">
              <label htmlFor="carNumber">車体番号</label>
              <select
                id="ClassA"
                className="form-control required"
                value={selectedCarNumber}
                onChange={(e) => setCarNumber(e.target.value)}
              >
                <option value="">車両を選択</option>
                {carNumbers.map((number) => {
                  const [value, label] = number.split(",");
                  return (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group no-flex text-end m-0">
              {/* <button
              type="button"
              className="btn btn-success btn-sm"
              onClick={handleCheckHistoryData}
            >
              前回登録データを参照
            </button> */}
              <button
                type="button"
                className="btn btn-success btn-sm ms-1"
                onClick={handleCheckHistoryDataTop10}
              >
                直近の履歴を参照
              </button>
            </div>
            <div className="form-group">
              <label htmlFor="department">費用負担部署コード</label>
              <select
                id="ClassB"
                className="form-control required"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(Number(e.target.value))}
              >
                <option value="">部署コードを選択</option>
                {departments.map((number) => {
                  const [value, label] = number.split(",");
                  return (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="driver">運転者（社員番号）</label>
              <input
                type="number"
                id="NumJ"
                className="form-control required"
                onChange={(e) => setSelectedDriver(Number(e.target.value))}
                defaultValue={selectedDriver}
              />
              <select
                id="ClassC"
                className="form-control"
                value={selectedDriver}
                onChange={(e) => setSelectedDriver(Number(e.target.value))}
                style={{ display: "none" }}
              >
                <option value="">運転者を選択</option>
                {drivers.map((number) => {
                  const [value, label] = number.split(",");
                  return (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-1">
              <label htmlFor="datetime">運転日時</label>
              <input
                id="DateA"
                type="datetime-local"
                className="form-control required"
                defaultValue={datetime}
              />
            </div>
            <div className="form-group no-flex text-end m-0">
              <button
                type="button"
                className="btn btn-success btn-sm"
                onClick={handleUpdateDate}
              >
                日時更新
              </button>
            </div>

            <div className="form-group">
              <label htmlFor="destination">訪問先</label>
              <input
                id="DescriptionB"
                type="text"
                className="form-control required"
                onFocus={(e) => {
                  e.target.select();
                }}
                defaultValue={destination}
              />
            </div>

            <div className="form-group">
              <label htmlFor="departureMeter">ODOメータ</label>
              <input
                id="NumH"
                type="number"
                className="form-control required"
                onFocus={(e) => {
                  e.target.select();
                }}
              />
            </div>

            <div className="form-group form-groupNumA">
              <label htmlFor="fuelAdded">給油量</label>
              <input
                id="NumA"
                type="number"
                className="form-control required"
                onFocus={(e) => {
                  e.target.select();
                }}
                defaultValue={0}
              />
            </div>

            <div className="form-group no-flex">
              <label htmlFor="notes">その他・同乗者</label>
              <textarea id="DescriptionC" className="form-control"></textarea>
              <div id="form-next-driver-msg" className="form-check">
                <input
                  id="CheckB"
                  type="checkbox"
                  className="form-check-input"
                  onChange={handleCheckChange}
                />
                <label className="form-check-label" htmlFor="CheckB">
                  次回運転者に申し送りあり
                </label>
                </div>
            </div>

            <div className="w-full text-center font-weight-bold">
              車体チェック<hr></hr>
            </div>

            <div className="form-group no-flex mb-3">
              <label className="control-label">車体損傷</label>
              {/*有無のラジオボタン */}
              <div>
                <input
                  id="CheckA-f"
                  type="radio"
                  className="form-check-input"
                  name="CheckA"
                  onChange={(e) => {
                    handleRadioChange(e);
                  }}
                  value="0"
                />
                <label className="form-check-label pl-5" htmlFor="CheckA-f">
                  無
                </label>
              </div>
              <div>
                <input
                  id="CheckA-t"
                  type="radio"
                  className="form-check-input"
                  name="CheckA"
                  onChange={(e) => {
                    handleRadioChange(e);
                  }}
                  value="1"
                />
                <label className="form-check-label" htmlFor="CheckA-t">
                  有
                </label>
              </div>
              <input
                id="CheckA"
                type="hidden"
                className="required"
                value={selectedCheckA}
              />
            </div>

            <div className="form-group no-flex">
              <label className="control-label">
                車体写真（損傷がある場合）
              </label>
              <div className="input-group">
                <input
                  id="AttachmentsA-file"
                  type="file"
                  className="d-none"
                  onChange={handleImageChange}
                />
                <input
                  type="text"
                  id="AttachmentsA"
                  className="form-control"
                  placeholder="画像を選択してください"
                  readOnly
                />
                <span className="input-group-btn">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      selectImage("AttachmentsA-file");
                    }}
                  >
                    参照
                  </button>
                </span>
              </div>
            </div>

            <div className={!requiredAlcohol ? "d-none" : ""}>
              <div className="w-full text-center font-weight-bold">
                アルコールチェック<hr></hr>
              </div>

              <div className="form-group">
                <label className="form-check-label" htmlFor="alcoholPC">
                  アルコール測定方法
                </label>
                <select
                  id="ClassD"
                  className="form-control"
                  onChange={(e) => {
                    handleClassDChange(e);
                  }}
                  value={selectedClassD}
                >
                  <option value="1">PC</option>
                  <option value="2">ポータブル</option>
                  <option value="3">対象外</option>
                </select>
              </div>

              <div
                className={
                  selectedClassD != "2" ? "form-group last" : "form-group"
                }
              >
                <label htmlFor="fuelAdded">アルコール検出値</label>
                <input
                  id="NumB"
                  type="number"
                  className="form-control"
                  defaultValue={0}
                />
              </div>

              <div className={selectedClassD != "2" ? "d-none" : ""}>
                <div>
                  <div className="form-group">
                    <label htmlFor="alcoholIdentifier">確認者</label>
                    <input
                      id="DescriptionD"
                      type="text"
                      className="form-control"
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="form-check-label"
                      htmlFor="alcoholCheckMeans"
                    >
                      確認方法
                    </label>
                    <select id="ClassG" className="form-control">
                      <option value=""></option>
                      <option value="1">対面</option>
                      <option value="2">TEL</option>
                      <option value="3">その他</option>
                    </select>
                  </div>
                </div>
                <div className="form-group no-flex last">
                  <label className="control-label">
                    アルコールチェック写真（ポータブルの場合）
                  </label>
                  <div className="input-group">
                    <input
                      id="AttachmentsB-file"
                      type="file"
                      className="d-none"
                      onChange={handleImageChange}
                    />
                    <input
                      type="text"
                      id="AttachmentsB"
                      className="form-control"
                      placeholder="画像を選択してください"
                      readOnly
                    />
                    <span className="input-group-btn">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          selectImage("AttachmentsB-file");
                        }}
                      >
                        参照
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Container>
      {
        //入力が完了していない場合のみボタン表示
        !inputCompleted && (
          <FixedBottomArea>
            <button
              type="submit"
              className="btn btn-primary mt-2 w-75"
              onClick={handleSubmit}
            >
              記録する
            </button>
          </FixedBottomArea>
        )
      }
    </>
  );
}
